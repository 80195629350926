<template>
	<div class="grid mb-4">
		<div class="title">Создание организации</div>
		<form class="organization" @submit.prevent>
			<BaseGroup title="Основные данные">
				<BaseInput v-model="organization.name" placeholder="Название" />
				<BaseInput v-model="organization.inn" placeholder="ИНН" />
			</BaseGroup>
			<BaseButton @click="saveOrganization"> Сохранить </BaseButton>
		</form>
	</div>
</template>

<script>
import BaseGroup from '@/components/ui/BaseGroup'
import BaseInput from '@/components/ui/BaseInput'
import BaseButton from '@/components/ui/BaseButton'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useApi } from '@/modules/api'

export default {
	name: 'OrganizationNew',
	components: {
		BaseInput,
		BaseGroup,
		BaseButton
	},
	setup () {
		const router = useRouter()
		const organization = ref({})

		const saveOrganization = async () => {
			const { post } = useApi('/organization/')
			await post({
				inn: organization.value.inn,
				name: organization.value.name
			})
			router.push({ name: 'Organizations' })
		}

		return {
			organization,
			saveOrganization
		}
	}
}
</script>

<style lang="scss">
	.organization {
		.group {
			width: 50%;
			margin-bottom: 20px;
		}
		.button {
			&:not(:last-of-type) {
				margin-right: 10px;
			}
		}
	}
</style>
